























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import EcTextField from 'common-components/src/components/form/ec-text-field.vue';
import { TableHeader } from '@/components/layout/models/table.d';
import { Field } from '@/models/form';
import {
  RuleParameterValue,
} from '@/models/library-maintenance';
import { operators } from '@/models/dropdowns'

@Component({
  components: {
    EcTextField,
  },
})
export default class RuleParameterEditor extends Vue {
  @Prop({ required: true }) field!: Field;

  parameterError = '';

  parameterHeaders: TableHeader[] = [
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      cellClass: 'text-no-wrap',
      width: '1px'
    },
    {
      text: 'Value',
      value: 'value',
      sortable: false,
    },
    {
      text: "Operator",
      value: "operator",
      sortable: false,
      width: '160px',
      class: "text-no-wrap px-1"
    },
    {
      text: 'Suppressed',
      value: 'is-suppressed',
      sortable: false,
      width: '1px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Regex',
      value: 'is-regex',
      sortable: false,
      width: '1px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Unordered',
      value: 'unordered',
      sortable: false,
      width: '1px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Fuzziness',
      value: 'fuzziness',
      sortable: false,
      width: '140px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: 'Proximity',
      value: 'proximity',
      sortable: false,
      width: '140px',
      align: 'center',
      class: 'text-no-wrap px-1'
    },
    {
      text: '',
      value: 'add',
      sortable: false,
      width: '1px',
    },
    {
      text: '',
      value: 'remove',
      sortable: false,
      width: '1px',
    },
  ];

  operators: any[] = operators;

  removeRow(item: any) {
    const array = this.field.value as RuleParameterRow[];

    const index = array.findIndex(
      (x) => x.name === item.name && x.value === item.value
    );
    this.field.value.splice(index, 1);
  }

  addRow(item: any) {
    const array = this.field.value as RuleParameterRow[];

    const newValue: RuleParameterRow = {
      name: item.name,
      value: '',
      type: item.type,
      'allow-multiple': true,
      unordered: false,
      'is-regex': false,
      fuzziness: 0,
      'is-suppressed': false,
      proximity: 0
    };

    const matchingParamNames = array.filter((x) => x.name === item.name);

    const index = array.findIndex((x) => x.name === item.name);

    array.splice(matchingParamNames.length + index, 0, newValue);

    if (matchingParamNames.length + index + 1 == array.length)
      window.scrollTo(0, document.body.scrollHeight + 100);
  }

  moreThanOneValue(valueName: string) {
    const valuesCount = this.field.value.filter(
      (parmeter: { name: string }) => parmeter.name === valueName
    ).length;
    return valuesCount > 1;
  }

  isParameterLastValue(value: string, name: string, index: number) {
    const array = this.field.value as RuleParameterValue[];
    return (index === array.length - 1) || (array[index + 1].name !== name);
  }

  regexChangedFor(item: any) {
    if (item['is-regex']) {
      item.fuzzinessEnabled = false;
    } else {
      item.fuzzinessEnabled = false;
    }
  }

  nextParameter(event: KeyboardEvent) {
    event.target
  }
}

declare interface RuleParameterRow {
  name: string;
  value: string;
  type: number;
  'allow-multiple': boolean;
  unordered: boolean;
  fuzziness: number;
  'is-regex': boolean;
  'is-suppressed': boolean;
  operator?: string;
  proximity: number;
}
